var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eventManagement-form-box" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false, "PREPARE")
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true, "FINISHED")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    "label-width": "150px",
                    disabled:
                      _vm.formType == "view" ||
                      _vm.dataForm.actStatus == "CONFIRMED",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "项目名称", prop: "projectName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  title: _vm.dataForm.projectName,
                                  placeholder: "请选择项目",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleDeptModeOpen("projectName")
                                  },
                                },
                                model: {
                                  value: _vm.dataForm.projectName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "projectName", $$v)
                                  },
                                  expression: "dataForm.projectName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "编制人员", prop: "weaveStaff" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请选择编制人员" },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleUserModeOpen()
                                  },
                                },
                                model: {
                                  value: _vm.dataForm.weaveStaff,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "weaveStaff", $$v)
                                  },
                                  expression: "dataForm.weaveStaff",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "编制日期:",
                                "label-width": "150px",
                                prop: "weaveDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择编制日期",
                                },
                                model: {
                                  value: _vm.dataForm.weaveDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "weaveDate", $$v)
                                  },
                                  expression: "dataForm.weaveDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "天气", prop: "weather" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入天气",
                                  title: _vm.dataForm.weather,
                                },
                                model: {
                                  value: _vm.dataForm.weather,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "weather", $$v)
                                  },
                                  expression: "dataForm.weather",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "巡检时长",
                                prop: "detectorHours",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入巡检时长",
                                  title: _vm.dataForm.detectorHours,
                                },
                                model: {
                                  value: _vm.dataForm.detectorHours,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "detectorHours", $$v)
                                  },
                                  expression: "dataForm.detectorHours",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "巡检里程",
                                prop: "detectorMileage",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入巡检里程",
                                  title: _vm.dataForm.detectorMileage,
                                },
                                model: {
                                  value: _vm.dataForm.detectorMileage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "detectorMileage",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.detectorMileage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "照片数量", prop: "photos" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入照片数量",
                                  title: _vm.dataForm.photos,
                                },
                                model: {
                                  value: _vm.dataForm.photos,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "photos", $$v)
                                  },
                                  expression: "dataForm.photos",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "计划整改",
                                prop: "planRectification",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入计划整改",
                                  title: _vm.dataForm.planRectification,
                                },
                                model: {
                                  value: _vm.dataForm.planRectification,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "planRectification",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.planRectification",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "完成整改",
                                prop: "completeRectification",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入完成整改",
                                  title: _vm.dataForm.completeRectification,
                                },
                                model: {
                                  value: _vm.dataForm.completeRectification,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "completeRectification",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.completeRectification",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "违规纠正",
                                prop: "correctDeregulations",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入违规纠正",
                                  title: _vm.dataForm.correctDeregulations,
                                },
                                model: {
                                  value: _vm.dataForm.correctDeregulations,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "correctDeregulations",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.correctDeregulations",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "劳保佩戴", prop: "laborWear" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入劳保佩戴",
                                  title: _vm.dataForm.laborWear,
                                },
                                model: {
                                  value: _vm.dataForm.laborWear,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "laborWear", $$v)
                                  },
                                  expression: "dataForm.laborWear",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "作业性违章",
                                prop: "taskDeregulations",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入作业性违章",
                                  title: _vm.dataForm.taskDeregulations,
                                },
                                model: {
                                  value: _vm.dataForm.taskDeregulations,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "taskDeregulations",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.taskDeregulations",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "指挥性违章",
                                prop: "commandDeregulations",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入指挥性违章",
                                  title: _vm.dataForm.commandDeregulations,
                                },
                                model: {
                                  value: _vm.dataForm.commandDeregulations,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "commandDeregulations",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.commandDeregulations",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "其他",
                                prop: "otherDeregulations",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入其他",
                                  title: _vm.dataForm.otherDeregulations,
                                },
                                model: {
                                  value: _vm.dataForm.otherDeregulations,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "otherDeregulations",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.otherDeregulations",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "施工班组名称及人数",
                                prop: "constructionNamePersonNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入施工班组名称及人数",
                                  title:
                                    _vm.dataForm.constructionNamePersonNumber,
                                },
                                model: {
                                  value:
                                    _vm.dataForm.constructionNamePersonNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "constructionNamePersonNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataForm.constructionNamePersonNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "检查区域", prop: "inspectArea" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "检查区域",
                                  title: _vm.dataForm.inspectArea,
                                },
                                model: {
                                  value: _vm.dataForm.inspectArea,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "inspectArea", $$v)
                                  },
                                  expression: "dataForm.inspectArea",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("head-layout", {
                    attrs: { "head-title": "项目现场施工和安全工作开展情况" },
                  }),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "昨日待整改的安全工作处理情况",
                                prop: "yesterdayRectificationDetail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder:
                                    "请输入昨日待整改的安全工作处理情况",
                                  title:
                                    _vm.dataForm.yesterdayRectificationDetail,
                                },
                                model: {
                                  value:
                                    _vm.dataForm.yesterdayRectificationDetail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "yesterdayRectificationDetail",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataForm.yesterdayRectificationDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "今日现场施工内容",
                                prop: "todayConstructionDetail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入今日现场施工内容",
                                  title: _vm.dataForm.todayConstructionDetail,
                                },
                                model: {
                                  value: _vm.dataForm.todayConstructionDetail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "todayConstructionDetail",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataForm.todayConstructionDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "今日安全工作内容",
                                prop: "todaySecurityDetail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入今日安全工作内容",
                                  title: _vm.dataForm.todaySecurityDetail,
                                },
                                model: {
                                  value: _vm.dataForm.todaySecurityDetail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "todaySecurityDetail",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.todaySecurityDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "明日作业台账、作业步骤梳理情况",
                                prop: "tomorrowTaskCardingDetail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder:
                                    "请输入明日作业台账、作业步骤梳理情况",
                                  title: _vm.dataForm.tomorrowTaskCardingDetail,
                                },
                                model: {
                                  value: _vm.dataForm.tomorrowTaskCardingDetail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "tomorrowTaskCardingDetail",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataForm.tomorrowTaskCardingDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("head-layout", {
                    attrs: { "head-title": "检查轨迹、照片" },
                  }),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上午路线轨迹",
                                prop: "fileMorningTrail",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  class: {
                                    uoloadSty: _vm.showImg,
                                    disUoloadSty: _vm.noneUploadImgCer,
                                  },
                                  attrs: {
                                    action:
                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                    headers: _vm.headers,
                                    "on-preview": _vm.handlePreview,
                                    "list-type": "picture-card",
                                    "file-list": _vm.fileMorningTrailList,
                                    file: "file",
                                    "on-remove": _vm.handleDeleteImgRemoveCer,
                                    "on-success": _vm.handleIdFilesSuccessCer,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    accept: ".jpeg,.jpg,.gif,.png",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    attrs: { slot: "default" },
                                    slot: "default",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "下午路线轨迹",
                                prop: "fileAfternoonTrail",
                              },
                            },
                            [
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("head-layout", { attrs: { "head-title": "日检照片" } }),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "日检照片",
                                prop: "fileMorningTrail",
                              },
                            },
                            [
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("head-layout", { attrs: { "head-title": "隐患整改" } }),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", [
                          _c("div", [_vm._v("整改前：")]),
                          _c(
                            "div",
                            [
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", [
                          _c("div", [_vm._v("整改后：")]),
                          _c(
                            "div",
                            [
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", [
                          _c("div", [_vm._v("整改前：")]),
                          _c(
                            "div",
                            [
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", [
                          _c("div", [_vm._v("整改后：")]),
                          _c(
                            "div",
                            [
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                              _c("el-image", {
                                staticClass: "securityPic",
                                attrs: { src: _vm.url },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("head-layout", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataForm.id,
            expression: "dataForm.id",
          },
        ],
        attrs: {
          "head-title": "问题清单",
          "head-btn-options":
            _vm.formType == "view" && _vm.dataForm.id ? [] : _vm.BtnOptions,
        },
        on: { "head-romve": _vm.headRomve, "head-add": _vm.headAdd },
      }),
      _c("grid-layout", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataForm.id,
            expression: "dataForm.id",
          },
        ],
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.rowView,
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.getTableList,
          "page-size-change": _vm.getTableList,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                row.hdHiddenDanger.hdStatus != "prepare" ||
                _vm.formType == "view"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowView(row)
                          },
                        },
                      },
                      [_vm._v("\n        查看\n      ")]
                    )
                  : _vm._e(),
                row.hdHiddenDanger.hdStatus == "prepare" &&
                _vm.userInfo.user_id == row.createUser &&
                _vm.formType != "view"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowEdit(row)
                          },
                        },
                      },
                      [_vm._v("\n        编辑\n      ")]
                    )
                  : _vm._e(),
                row.hdHiddenDanger.hdStatus == "prepare" &&
                _vm.userInfo.user_id == row.createUser &&
                _vm.formType != "view"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowDel([row])
                          },
                        },
                      },
                      [_vm._v("\n        删除\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "项目选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { deptCategory: [2, 5] },
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _vm.zxhcModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "avue-dialog avue-dialog--top",
              attrs: {
                title: "问题登记",
                visible: _vm.zxhcModel,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.zxhcModel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "modelFormDataForm",
                  attrs: {
                    model: _vm.modelForm,
                    "label-width": "150px",
                    disabled: _vm.modelForm.disabled,
                    rules: _vm.modelFormRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患编号", prop: "hdCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "隐患编号自动生成",
                                },
                                model: {
                                  value: _vm.modelForm.hdCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelForm, "hdCode", $$v)
                                  },
                                  expression: "modelForm.hdCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查地点", prop: "location" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请填写检查地点" },
                                  model: {
                                    value: _vm.modelForm.location,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "location", $$v)
                                    },
                                    expression: "modelForm.location",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _c("div", {
                                      staticClass: "el-icon-map-location",
                                      staticStyle: {
                                        width: "54px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openMap()
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患描述", prop: "hdDesc" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-container" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请填写隐患描述",
                                    },
                                    model: {
                                      value: _vm.modelForm.hdDesc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelForm, "hdDesc", $$v)
                                      },
                                      expression: "modelForm.hdDesc",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "hdDesc-mar" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDesc()
                                            },
                                          },
                                        },
                                        [_vm._v("常见隐患")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报告人",
                                prop: "reportUserName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请选择报告人" },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleUserModeOpen()
                                  },
                                },
                                model: {
                                  value: _vm.modelForm.reportUserName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modelForm,
                                      "reportUserName",
                                      $$v
                                    )
                                  },
                                  expression: "modelForm.reportUserName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "报告时间", prop: "reportDate" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "请选择报告时间",
                                },
                                model: {
                                  value: _vm.modelForm.reportDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelForm, "reportDate", $$v)
                                  },
                                  expression: "modelForm.reportDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患现场", prop: "treeData" } },
                            [
                              _c(
                                "div",
                                { staticClass: "content" },
                                _vm._l(_vm.treeData, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "img-content",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showFile(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.thumbnailLink,
                                          alt: "",
                                        },
                                      }),
                                      _vm.formType !== "view"
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-close delete-icon",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleRemove(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.handleBtn,
                        disabled: _vm.handleBtn,
                      },
                      on: {
                        click: function ($event) {
                          _vm.zxhcModel = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  !_vm.modelForm.disabled
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.handleBtn,
                            disabled: _vm.handleBtn,
                            type: "primary",
                          },
                          on: { click: _vm.handleZxhc },
                        },
                        [_vm._v("\n        确 定\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": "",
            visible: _vm.mapBox,
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _vm.mapBox
            ? _c("GaodeMap", {
                ref: "GaodeMap",
                on: {
                  selectAddress: _vm.selectAddress,
                  getLocation: _vm.getLocation,
                },
              })
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.mapBox = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "项目选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("sortSetting", { ref: "sortSetting", on: { callback: _vm.callback } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }