<template>
  <div style="height: 100%">
 <div class="checklist-edit">
   <div class="step-box">
     <head-layout :show-icon="false" head-title="处理进度" ></head-layout>
     <el-steps direction="vertical" :active="3" style="margin-top: 30px;align-items: center;height: 50%;">
       <el-step title="上报"></el-step>
       <el-step title="评估"></el-step>
       <el-step title="整改"></el-step>
       <el-step title="验收"></el-step>
     </el-steps>
   </div>
  <div class="form-box">
    <div class="">
      <head-layout :head-title="headTitle" :head-btn-options="btnOptions" @head-cancel="headCancel"></head-layout>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm" :model="dataForm" label-width="150px" disabled :rules="rules">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="隐患编号" prop="hdCode">
                    <el-input v-model="dataForm.hdCode" disabled placeholder="请输入隐患编号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="隐患地点" prop="prjName">
                    <el-input  v-model="dataForm.location" :title="dataForm.location" placeholder="请填写隐患地点"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="项目名称" prop="organizationName">
                    <el-input v-model="dataForm.organizationName" ref="organizationName" placeholder="请选择被通知项目" :title="dataForm.organizationName">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="项目区域" prop="area">
                    <el-input v-model="dataForm.area" ref="areaName" placeholder="请选择项目区域" :title="dataForm.area" >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="报告人" prop="reportUserName">
                    <el-input v-model="dataForm.reportUserName"  placeholder="请填写报告人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告部门" prop="reportDeptName">
                    <el-input v-model="dataForm.reportDeptName" :title="dataForm.reportDeptName" placeholder="请填写报告部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告时间" prop="reportDate">
                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.reportDate"  placeholder="请选择报告时间"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="隐患描述" prop="hdDesc">
                    <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="dataForm.hdDesc" placeholder="请填写隐患描述" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="隐患来源" prop="sourceType">
                    <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm.sourceType" placeholder="请选择隐患来源" type="tree" :dic="HD_SOURCE_TYPE"></avue-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="隐患图片" prop="prjAs">
                    <div class="content">
                      <div
                        v-for="(item, index) in treeData"
                        :key="item.id"
                        class="img-content"
                        @click="getNodeClick(item)"
                      >
                        <img :src="item.thumbnailLink" alt=""/>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <head-layout head-title="隐患评估" ></head-layout>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm" :model="dataForm2" label-width="150px" disabled>
              <el-row>
                <!-- <el-col :span="8">
                  <el-form-item label="隐患核实" prop="passFlag">
                    <el-radio v-model="dataForm2.passFlag" label="y">核实通过</el-radio>
                    <el-radio v-model="dataForm2.passFlag" label="n">核实不通过</el-radio>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="隐患等级" prop="prjName">
                      <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm2.hdLevel" placeholder="请选择隐患等级" type="tree" :dic="hd_level"></avue-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="隐患类型" prop="hdType">
                    <avue-select :props="{label: 'dictValue',value: 'dictKey'}" v-model="dataForm2.hdType" placeholder="请选择隐患类型" type="tree" :dic="hd_type"></avue-select>
                  </el-form-item>
                </el-col>
                <template>
                  <el-col :span="8" v-if="dataForm2.hdType == '1'">
                    <el-form-item label="隐患因素" prop="hdFactors">
                      <avue-select
                        :props="{ label: 'dictValue', value: 'dictKey' }"
                        v-model="dataForm2.hdFactors"
                        placeholder="请选择隐患因素"
                        type="tree"
                        :dic="hd_factors"
                      ></avue-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="dataForm2.hdType == '1'">
                    <el-form-item label="隐患后果" prop="hdResult">
                      <avue-select
                        :props="{ label: 'dictValue', value: 'dictKey' }"
                        v-model="dataForm2.hdResult"
                        placeholder="请选择隐患后果"
                        type="tree"
                        :dic="hd_result"
                      ></avue-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="dataForm2.hdType == '2'">
                    <el-form-item label="违章类型" prop="hdBreakRules">
                      <avue-select
                        :props="{ label: 'dictValue', value: 'dictKey' }"
                        v-model="dataForm2.hdBreakRules"
                        placeholder="请选择违章类型"
                        type="tree"
                        :dic="hd_break_rules"
                      ></avue-select>
                    </el-form-item>
                  </el-col>
                </template>
              
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改单位" prop="sourceType">
                    <el-input v-model="dataForm2.rectifyDeptName"   placeholder="请选择整改部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改人" prop="sourceType">
                    <el-input v-model="dataForm2.rectifyUserName"   placeholder="请选择整改人"></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8" v-if="dataForm2.passFlag == 'n' || dataForm2.passFlag == 'y'">
                  <el-form-item label="评估时间" prop="assessmentTime">
                    <el-date-picker  type="datetime"  value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm2.assessmentTime"  placeholder="请选择评估时间"></el-date-picker>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改期限" prop="rectifyLimitDate">
                    <el-date-picker type="date"   value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm2.rectifyLimitDate"  placeholder="请选择整改期限"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改要求" prop="rectifyRequest">
                    <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="dataForm2.rectifyRequest" placeholder="请填写整改要求" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" v-if="dataForm2.passFlag == 'n'">
                  <el-form-item label="备注" prop="remark">
                    <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="dataForm2.remark" placeholder="请填写整改要求" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hide != 'hide'" class="">
     <div>
       <head-layout head-title="隐患整改" :head-btn-options="headBtnOptions"  @head-saveBack="headSave(true)"  @head-save="headSave(false)">
         <template slot="right">
           <el-dropdown >
             <el-button type="primary" size="mini">
               整改历史
             </el-button>
             <el-dropdown-menu slot="dropdown">
               <el-dropdown-item @click.native="handleDropdown(item)" v-for="item in historyList" class="list-decoration">{{item.rectifyDate}}</el-dropdown-item>
             </el-dropdown-menu>
           </el-dropdown>
         </template>
       </head-layout>
     </div>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm1" :model="dataForm1" label-width="150px" :disabled="formType == 'view'" :rules="formType == 'view' ? {} :rules">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="整改完成时间" prop="rectifyDate">
                    <el-date-picker type="date"  value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.rectifyDate"  placeholder="请选择完成时间"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8" >
                  <el-form-item label="实际整改人" prop="actualRectifyUserName">
                    <el-input v-model="dataForm1.actualRectifyUserName" @focus="respUserShow=true" placeholder="请选择实际整改人"></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :span="24">
                  <el-form-item label="整改措施" prop="remark">
                    <el-input type="textarea" maxlength="500" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm1.remark" placeholder="请填写整改要求" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row >
                <el-col :span="24">
                  <el-form-item label="整改结果" prop="treeData4" >
                    <el-upload
                      v-if="formType !== 'view'"
                      accept=".png,.jpg"
                      style="margin-bottom: 12px;display: flex; align-items: center;"
                      class="upload-demo"
                      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                      :show-file-list="false"
                      :before-upload="checkFileType1"
                      :on-success="handleAvatarSuccess1"
                      ref="uploadRef"
                      :headers="headers"
                    >
                      <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-upload"
                      >点击上传
                      </el-button>
                    </el-upload>
                    <div class="content">
                      <div
                        v-for="(item, index) in treeData4"
                        :key="item.id"
                        class="img-content"
                        @click="getNodeClick(item)"
                      >
                        <img :src="item.thumbnailLink" alt=""/>
                        <i
                          v-if="formType !== 'view'"
                          class="el-icon-circle-close delete-icon"
                          @click.stop="handleRemove1(item, index)"
                        ></i>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
    <el-dialog title="人员选择" :visible.sync="respUserShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="respUserFun"></UserDetpDialog>
    </el-dialog>
    <el-dialog title="整改历史" :visible.sync="historyShow" width="80%">
      <el-form ref="dataForm" :model="modelDataForm" label-width="150px" disabled >
        <el-row>
          <el-col :span="8">
            <el-form-item label="完成时间" prop="rectifyDate">
              <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="date"  v-model="modelDataForm.rectifyDate"  placeholder="请选择完成时间"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item label="实际整改人" prop="actualRectifyUserName">
              <el-input v-model="modelDataForm.actualRectifyUserName"  placeholder="请选择实际整改人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="整改措施" prop="remark">
              <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="modelDataForm.remark" placeholder="请填写整改要求" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <!-- <el-col :span="24">
            <el-form-item label="整改计划" prop="prjAs">
              <el-upload
                disabled
                :on-preview="handlePreview"
                class="upload-demo"
                :file-list="modelDataFormImgList"></el-upload>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="整改结果" prop="prjAs">
              <div class="content">
                <div
                  v-for="(item, index) in modelDataFormList"
                  :key="item.id"
                  class="img-content"
                  @click="getNodeClick(item)"
                >
                  <img :src="item.thumbnailLink" alt=""/>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
        v-dialog-drag
        title="附件预览"
        :modal="true"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :visible.sync="showFileDialog"
        width="70%"
    >
      <iframe
          ref="fileIframe"
          :src="fileUrl"
          frameborder="0"
          style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import * as  API from "@/api/riskitemView/rectify";
  import {detail} from "@/api/riskitemView/checklist";
  import {getToken} from "@/util/auth";
  import website from "@/config/website";
  import Template from "@/views/message/template/list.vue";
  import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
  import {dateFormat} from "@/util/date";
  import {mapGetters} from "vuex";
  import snows from "@/util/snows";

  export default {
    components: {
      UserDetpDialog,
      Template
    },
    data() {
      return {
        hide:'',
        fileUrl: '',
        showFileDialog: false,
        historyShow:false,
        modelDataForm:{},
        modelDataFormImgList:[],
        modelDataFormList:[],
        treeData:[],
        historyList:[],
        treeData1:[],
        treeData3:[],
        treeData4:[],
        treeData2:[],
        sourceDic:[],
        hd_level:[],
        HD_SOURCE_TYPE:[],
        hd_type:[],
        hd_factors: [],
        hd_result: [],
        hd_break_rules: [],
        formType: '',
        headTitle: '',
        mapBox: false,
        respUserShow: false,
        mapdata: '',
        dataForm: {},
        dataForm2: {},
        dataForm1: {
          treeData3:[],
          treeData4:[],
          rectifyDate:dateFormat(new Date())
        },
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        rules: {
          rectifyDate: [{
            required: true,
            message: '请选择完成时间',
            trigger: 'blur'
          }],
          treeData4: [{
            required: true,
            message: '请上传整改结果',
            trigger: 'blur'
          }],
          remark: [{
            required: true,
            message: '请输入整改措施',
            trigger: 'blur'
          }],
        },
        selectionList: [],
      }
    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      data() {
        return data
      },
      headers: function () {
        return {"Sinoma-Auth": getToken()};
      },
      btnOptions(){
        return [{
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        }]
      },
      headBtnOptions() {
        let result = [];
        if (['add', 'edit'].includes(this.formType)) {
          result.push({
              label: "提交",
              emit: "head-saveBack",
              type: "button",
              btnOptType: "primary",
            },
            {
              label: '暂存',
              emit: "head-save",
              type: "button",
              btnOptType: "primary",
            }
          );
        }
        return result;
      },
    },
    mounted() {
      let type = this.$route.query.type;
      this.hide = this.$route.query.hide;
      this.formType = type;
      if (type == 'edit') {
        this.headTitle = '隐患整改';
        this.dataForm.id = this.$route.query.id;
        this.dataForm1.actualRectifyUser=this.userInfo.user_id
        this.dataForm1.actualRectifyUserName=this.userInfo.real_name
        this.getPrjbasicinfoDetail();
      } else if (type == 'view') {
        this.headTitle = '隐患整改';
        this.dataForm.id = this.$route.query.id;
        this.getPrjbasicinfoDetail();
      }
      this.getCode('HD_SOURCE_TYPE')
      this.getCode('hd_level')
      this.getCode('hd_type')
      this.getCode("hd_factors");
      this.getCode("hd_result");
      this.getCode("hd_break_rules");
    },
    methods: {
      handlePreview(file){
        snows.downloadFile1(file.link,file.name)
      },
      handleDropdown(item){
        this.modelDataForm=item;
        this.modelDataFormImgList=item.rectifyPlanUrl && JSON.parse(item.rectifyPlanUrl)
        this.modelDataFormList=item.rectifyUrl && JSON.parse(item.rectifyUrl)
        this.historyShow=true
      },
      respUserFun(row){
          this.dataForm1.actualRectifyUser=row.id
          this.dataForm1.actualRectifyUserName=row.realName
        this.respUserShow=false
        console.log("进行输出人员-------------------",row)
      },
      getCode(code){
        API.HD_SOURCE_TYPE(code).then(res=>{
          this[code]=res.data.data
        })
      },
      handleAvatarSuccess(res, file, fileList) {
        let list = [];
        fileList.map((i) => {
          if (i.response) {
            list.push({
              extension: i.response.data.type,
              id: i.response.attachId,
              name: i.name,
              link: i.response.data.link,
              url: i.response.data.link,
            });
          } else {
            list.push({
              extension: i.type,
              id: i.attachId,
              link: i.link,
              name: i.name,
              url: i.url
            });
          }
        })
        this.treeData3 = list;
        this.$set(this.dataForm1,'treeData3',list)
        this.$refs.dataForm1.validateField('treeData3')
      },
      handleAvatarSuccess1(res, file, fileList) {
        this.treeData4.push({
          extension: file.raw.type,
          id: file.response.data.attachId,
          link: file.response.data.link,
          thumbnailLink: file.response.data.thumbnailLink,
          name: file.response.data.originalName,
          originalName: file.response.data.name,
        });
        this.$set(this.dataForm1,'treeData4',this.treeData4)
        this.$refs.dataForm1.validateField('treeData4')
      },
      checkFileType(file){
        const fileType = file.name.substring(file.name.lastIndexOf('.'));
        const isLt20M = file.size / 1024 / 1024 < 20;
        const fileTran=['.rar','.zip','.doc','.docx','.pdf'];
        const isJPG = fileTran.includes(fileType);

        if (!isJPG) {
          this.$message.warning('上传图片只能是 .rar  .zip .docx .pdf格式!');
        }
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isJPG && isLt20M;
      },
      checkFileType1(file){
        const fileType = file.name.substring(file.name.lastIndexOf('.'));
        const isLt20M = file.size / 1024 / 1024 < 20;
        const isJPG = (fileType === '.png' ||  fileType == '.jpg');

        if (!isJPG) {
          this.$message.warning('上传图片只能是 .png  .png  格式!');
        }
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isJPG && isLt20M;
      },
      headCancel() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },
      // 保存
      headSave(boolean) {
        this.$refs.dataForm1.validate((valid) => {
          if (valid) {
            this.$loading();
            let url=this.dataForm1.id ? API.update: API.save
            API.save({
              ...this.dataForm1,
              hdStatus:boolean ?'unaccept' : 'prepare',
              hdId:this.dataForm.id,
              rectifyPlanUrl:JSON.stringify(this.treeData3),
              rectifyUrl:JSON.stringify(this.treeData4),
            }).then((res) => {
              if (res.data.code == 200) {
                if (boolean) {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                }else {
                  this.getPrjbasicinfoDetail()
                }
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.$loading().close();
              }
            }).catch(err=>{
              this.$loading().close();
            })
          }
        });

      },
      // 根据ID查表单详情
      getPrjbasicinfoDetail() {
        detail({
          id: this.dataForm.id
        }).then((res) => {
          if (res.data.code == 200) {
            this.dataForm = res.data.data;
            this.treeData=this.dataForm.hdUrl ? JSON.parse(this.dataForm.hdUrl) : []
            if(JSON.stringify(res.data.data.hdAssessmentVO) != '{}'){
              this.dataForm2 = res.data.data.hdAssessmentVO;
            }
            if(this.formType == 'view' &&JSON.stringify(res.data.data.hdRectifyVO) != '{}'){
              this.dataForm1 = res.data.data.hdRectifyVO;
            }
            if(this.dataForm.hdStatus == 'unrectify' &&JSON.stringify(res.data.data.hdRectifyVO) != '{}'){
              this.dataForm1 = res.data.data.hdRectifyVO;
              this.treeData3=JSON.parse(res.data.data.hdRectifyVO.rectifyPlanUrl)
              this.treeData4=JSON.parse(res.data.data.hdRectifyVO.rectifyUrl)
              this.$set(this.dataForm1,'treeData3',this.treeData3)
              this.$set(this.dataForm1,'treeData4',this.treeData4)
            }
            if(res.data.data.hdAssessmentVO.assessmentUrl){
              this.treeData1=JSON.parse(res.data.data.hdAssessmentVO.assessmentUrl)
            }
            if(res.data.data.hdAssessmentVO.punishUrl){
              this.treeData2=JSON.parse(res.data.data.hdAssessmentVO.punishUrl)
            }
            if(this.formType == 'view' && res.data.data.hdRectifyVO.rectifyPlanUrl){
              this.treeData3=JSON.parse(res.data.data.hdRectifyVO.rectifyPlanUrl)
            }

            if(this.formType == 'view' && res.data.data.hdRectifyVO.rectifyUrl){
              this.treeData4=JSON.parse(res.data.data.hdRectifyVO.rectifyUrl)
            }
            if(this.dataForm2.rectifyPlanFlag =='y'){
              this.$set(this.rules,'treeData3',[{
                required: true,
                message: '请上传整改计划',
                trigger: 'change'
              }])
            }
          }
        })
        API.List({
          hdId: this.dataForm.id,
          size:9999,
          current:1,
        }).then(res=>{
          this.historyList=res.data.data.records
        })
      },
      getNodeClick(node) {
        this.selectNode = node;
        this.showFile(node);
      },
      handleRemove(file, fileList) {
        let list = new Array();
        fileList.forEach((i) => {
          if (i.response) {
            list.push({
              extension: i.response.data.type,
              id: i.response.attachId,
              originalName: file.response.data.name,
              name: i.name,
              link: i.response.data.link,
              url: i.response.data.link,
            });
          } else {
            list.push({
              extension: i.type,
              id: i.attachId,
              link: i.link,
              name: i.name,
              originalName: i.originalName,
              url: i.url
            });
          }
        })
        this.treeData3 = list;
        this.dataForm1.treeData3 = list;
      },
      handleRemove1(item, index) {
        this.treeData4.splice(index, 1);
      },
      showFile(item) {
        this.fileUrl =
          process.env.VUE_APP_BASE_KKFILEURL+"?url=" +
          encodeURIComponent(Base64.encode(item.link));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      },
    }
  }

</script>
<style lang="scss" scoped>
  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }

  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
  .step-box{
    background-color: #ffffff;
    width: 125px;
  }
  .form-box{
    background-color: #ffffff;
    width: calc(100% - 135px);
    height: 100%;
    overflow: scroll;
  }
  .list-decoration{
    text-decoration: underline;
  }



  .leftRightBtn {
    margin: 211px 12px;
  }
  .checklist-edit{
    display: flex;
    height: 100%;
    justify-content: space-between;
  }



   .content {
    display: flex;
    align-items: center;
    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;
      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

  }
</style>
